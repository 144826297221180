import { IconButton, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"
import CloseIcon from "@material-ui/icons/Close"
import DrawerTextField from "./utils/DrawerTextField"
import DrawerRedButton from "./utils/DrawerRedButton"
import Spinner from "../../components/Loader/Spinner"
import apiClient from "../../../api/apiClient"
import { notificationsApi } from "../../../api/apiRequests"
import { useDispatch, useSelector } from "react-redux"
import { setSuccessAlert } from "../../../redux/slices/errorAlertSlice"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  titleRoot: {
    width: "97%",
    display: "flex",
    marginTop: "40px",
    marginBottom: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "15px",
  },
  rootForm: {
    width: "100%",
  },
  rootMainForm: {
    paddingLeft: "15px",
    paddingRight: "15px ",
  },
  rootButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    bottom: "0",
    right: "0",
    position: "absolute",
    marginBottom: "20px",
  },
  subButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: "25px",
  },
  typoTitle: {
    fontFamily: "DM Sans",
    fontSize: "30px",
    fontWeight: 400,
  },
  typosubTitle: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
}))

function SendNotifications({ close }) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const user = useSelector(state => state.userSlice.user)

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    title: "",
    message: "",
  })

  const handleChange = (name, value) => {
    if (value) {
      let err = { ...errors }
      delete err[name]
      setErrors(err)
    }
    setFormValues({ ...formValues, [name]: value })
  }

  const sendDataToApi = async () => {
    setLoading(true)
    try {
      const res = await apiClient(
        notificationsApi.sendNotification({
          ...formValues,
          userId: user.uid,
          clubId: user.userInfo.clubId,
        })
      )
      dispatch(
        setSuccessAlert({
          isVisible: true,
          message: "Notification sent successfully",
          title: "success",
          severity: "success",
        })
      )
      close()
    } catch (e) {
      console.log("error submit", e)
    }
    setLoading(false)
  }

  const handleValidation = () => {
    let err = {}
    Object.keys(formValues).map(item => {
      if (formValues[item] === "") {
        err[item] = "required*"
      } else {
        delete err[item]
        close()
      }
      setErrors(err)
    })

    if (Object.keys(errors).length === 0) {
      sendDataToApi()
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleRoot}>
        <div>
          <Typography className={classes.typoTitle} style={{ margin: "13px" }}>
            Send notification
          </Typography>
          <Typography
            className={classes.typosubTitle}
            style={{ margin: "13px" }}
          >
            All your members will recieve a notification from you
          </Typography>
        </div>
        <IconButton onClick={close}>
          <CloseIcon style={{ margin: "13px" }} />
        </IconButton>
      </div>
      <div className={classes.rootForm}>
        <div className={classes.rootMainForm}>
          <div style={{ width: "100%" }}>
            <DrawerTextField
              errors={errors?.title}
              onChange={e => handleChange("title", e.target.value)}
              value={formValues?.title}
              placeHolder="Hello!"
              label="Title of notification"
            />
          </div>
          <div style={{ width: "100%" }}>
            <DrawerTextField
              errors={errors?.message}
              onChange={e => handleChange("message", e.target.value)}
              value={formValues?.message}
              placeHolder="Maximum 300 characters"
              label="Message"
              rows={10}
              multiline={true}
              inputProps={{ maxLength: 300 }}
            />
          </div>

          <div className={classes.rootButton}>
            <div className={classes.subButton}>
              {loading ? (
                <Spinner />
              ) : (
                <DrawerRedButton
                  text="Send"
                  handleOnClick={() => handleValidation()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendNotifications
